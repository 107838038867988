import React, { useState, useRef, useEffect } from "react";
// import Helmet from 'react-helmet';
// import { BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";

import { StaticImage } from "gatsby-plugin-image";
import Image from "gatsby-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { graphql, Link } from "gatsby";

import { List, ListItem, ListItemText } from "@material-ui/core";

import Divider from "@material-ui/core/Divider";

import Grid from "@material-ui/core/Grid";
import NiinaHeader from "../components/NiinaHeader";
import NiinaFooter from "../components/NiinaFooter";

import { Helmet } from "react-helmet-async";

function Menu({ data }) {
  return (
    <>
      <Helmet>
        <html lang="ja" />
        <title>Bakery Niina / ベーカリー・ニーナ | メニュー</title>
        <meta
          name="description"
          content="山形のみなさんに、パン職人が一晩かけて仕込むこだわりパンを一番おいしい状態で届けたい。焼き立てパンが香る店『Bakery Niina（ベーカリーニーナ）』です。多
い日には１日５０回焼き上げるため、いつでも焼きたてのパンが楽しめます。"
        />
      </Helmet>
      <div style={{ minWidth: "320px" }}>
        <NiinaHeader />
        <MenuComponent data={data} />
        <NiinaFooter />
      </div>
    </>
  );
}

export default Menu;

function MenuComponent({ data }) {
  const menus = data.allMicrocmsMenus.edges.reverse();

  return (
    <div className="App">
      <div
        style={{
          display: "row",
        }}
      >
        <StaticImage
          style={{
            marginTop: "1em",
            minWidth: "160px",
            maxWidth: "30%",
          }}
          src="../images//img/photos/ヘッダーロゴ@3x.png"
        />
      </div>

      <div
        style={{
          marginTop: "3.2em",
          display: "row",
          fontSize: "3.125vw",
        }}
      >
        <StaticImage
          // layout="fullWidth"
          height="1rem"
  
          // width="100%"
          // // width="100vw"
          // height="3.125vw"        
          // imgStyle={{
          //   height: "100%",
          //   width: "auto",
          // // }}
          // style={{ 
          //   // width: "auto",
          //   // height: "3.125vw",
          //   // minWidth: "160px", maxWidth: "70%" 
          // }}
          src="../images//img/1x/menu-midasi.png"
        />
      </div>

      <Grid
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        container
        style={{
          margin: 0,
          padding: 0,
          minWidth: "320px",
          position: "relative",
        }}
      >
        <div style={{ fontSize: mainFontSize, width: "100%" }}>
          <p>ニーナの定番メニューです。</p>
          <p>
            季節のメニューに関しては、
            <br />
            SNSをチェックしてください！
          </p>
        </div>

        {menus.map((m) => {
          return (
            <div style={{ width: "100%", padding: "5%" }}>
              <img width="70%" src={m.node.image.url} />
              <Grid
                direction="row"
                // justify="space-between"
                alignItems="flex-start"
                container
                style={{
                  width: "70%",
                  margin: "auto",
                  marginBottom: "5vh",
                  padding: 0,
                  minWidth: "320px",
                  position: "relative",
                }}
              >
                <span style={{ marginRight: "2vw", fontWeight: "bold", fontSize: boldFontSize }}>
                  {m.node.name}
                </span>{" "}
                <span style={{ fontSize: mainFontSize }}>
                  {m.node.price}円【税込】
                </span>
                <span style={{ textAlign: "left", fontSize: mainFontSize }}>
                  {m.node.description}
                </span>
              </Grid>
              <Divider />
            </div>
          );
        })}
      </Grid>
    </div>
  );
}

const mainFontSize = "3.8vw";
const boldFontSize = "3.9vw";

export const query = graphql`
  {
    allMicrocmsMenus {
      edges {
        node {
          price
          id
          name
          image {
            url
          }
          description
        }
      }
    }
  }
`;
